import React from 'react';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton, RedditIcon, RedditShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";

const SociaLShareButtons = ({title, iconSize=32, url=""}) => {
    return (
        <div className="article-share">
            <ul className="social">
                <li><span>share:</span></li>
                <li style={{margin: "3px"}}>
                    <FacebookShareButton
                        url={url}
                        quote={title}
                        className="Demo__some-network__share-button"
                    >
                        <FacebookIcon size={iconSize} round/>
                    </FacebookShareButton>
                </li>
                <li style={{margin: "3px"}}>
                    <TwitterShareButton
                        url={url}
                        title={title}
                    >
                        <TwitterIcon size={iconSize} round/>
                    </TwitterShareButton>
                </li>
                <li style={{margin: "3px"}}>
                    <LinkedinShareButton
                        url={url}
                        className="Demo__some-network__share-button">
                        <LinkedinIcon size={iconSize} round />
                    </LinkedinShareButton>
                </li>
                <li style={{margin: "3px"}}>
                    <RedditShareButton
                        url={url}
                        title={title}
                        windowWidth={660}
                        windowHeight={460}
                        className="Demo__some-network__share-button"
                    >
                        <RedditIcon size={iconSize} round />
                    </RedditShareButton>
                </li>
                <li style={{margin: "3px"}}>
                    <EmailShareButton
                        url={url}
                        subject={title}
                        body="body"
                        className="Demo__some-network__share-button"
                    >
                        <EmailIcon size={iconSize} round />
                    </EmailShareButton>
                </li>
            </ul>
        </div>
    );
};

export default SociaLShareButtons;
